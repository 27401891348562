import { graphql } from 'gatsby'
import * as React from 'react'

import BlogList from '../components/Blog/List'
import BlogNav from '../components/Blog/Nav'
import Pagination from '../components/Blog/Pagination'
import Newsletter from '../components/Newsletter'
import { Container } from '../components/shared/Grid'
import { MainTitle, Title } from '../components/shared/Typography'
import { IBlog } from '../interfaces/blog'
import Layout from '../layouts'
import { generateAuthorUrl } from '../utils/generateUrl'
import SEO from '../components/SEO'

const AuthorList: React.FC<IBlog> = ({ data, pageContext }) => {
  const authors = Array.isArray(pageContext.allAuthors)
    ? pageContext.allAuthors
    : []
  const navData = authors.map(n => ({
    label: n,
    url: generateAuthorUrl(n),
  }))

  return (
    <Layout>
      <SEO />
      <Container>
        <BlogNav data={navData}>
          <Title mt={['4rem']} width={['100%', '80%', '50%']} />
          <MainTitle
            fontSize={['1.75rem', '2.5rem']}
            mb={['4rem']}
            width={['100%', '80%', '50%']}
          >
            {pageContext.author}'s Articles
          </MainTitle>
        </BlogNav>

        <BlogList posts={data.allMarkdownRemark.edges} />

        <Pagination
          total={pageContext.numPages!}
          current={pageContext.currentPage!}
          path={generateAuthorUrl(pageContext.author!)}
        />
      </Container>

      <Newsletter />
    </Layout>
  )
}

export default AuthorList

export const query = graphql`
  query AuthorBySlug($author: String, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { author: { in: [$author] } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 160)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            category
            author
            hero {
              childImageSharp {
                fluid {
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }
      }
    }
  }
`
